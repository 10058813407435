<template>
  <vx-card v-show="!loading">
    <vs-row>
      <h4 class="mb-5">Edit Category Product</h4>
    </vs-row>

    <vs-row vs-w="12">
      <vs-col vs-w="12" class="mb-3">
        <vs-input
          class="w-full"
          icon-pack="feather"
          icon="icon-box"
          icon-no-border
          label="Category Product Name (*)"
          v-model="payload.name"
        />
        <span class="block text-sm mt-2 text-danger">{{
          errors.first("name")
        }}</span>
      </vs-col>

      <vs-col vs-w="12" class="mt-3">
        <div class="vx-col w-full" @click.prevent="storeData">
          <vs-button class="mr-3 mb-2">Submit</vs-button>
        </div>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
// Store Module
import moduleCategoryProductManagement from "@/store/category-product-management/moduleCategoryProductManagement.js";

export default {
  name: "CategoryProductEdit",

  metaInfo: {
    title: "Edit Category Product",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },

  data: () => ({
    payload: {
      name: ""
    },

    loading: true
  }),

  computed: {
    decryptedID() {
      return this.$secure.decrypt(this.$route.params.id);
    }
  },

  methods: {
    getData() {
      this.$vs.loading({
        type: 'sound'
      })

      this.$store
        .dispatch(
          "categoryProductManagement/fetchCategoryProduct",
          this.decryptedID
        )
        .then(({ data: res }) => {
          this.payload.name = res.data.name;
        })
        // .catch(err => this.$catchErrorResponse(err))
        .finally(() => {
          this.loading = false
          this.$vs.loading.close()
        })
    },

    storeData() {
      const formData = new FormData();
      // set formData
      formData.set("name", this.payload.name);
      formData.set("_method", "PUT");

      this.$store
        .dispatch("categoryProductManagement/updateCategoryProduct", {
          id: this.decryptedID,
          body: formData
        })
        .then(({ data: res }) => {
          this.$vs.notify({
            title: "Successful",
            text: res.msg_status,
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success"
          });

          this.$router.push("/category-products");
        })
        .catch(err => {
          this.$catchErrorResponse(err)
        });
    }
  },

  created() {
    if (!moduleCategoryProductManagement.isRegistered) {
      this.$store.registerModule(
        "categoryProductManagement",
        moduleCategoryProductManagement
      );
      moduleCategoryProductManagement.isRegistered = true;
    }

    this.getData();
  }
};
</script>

<style></style>
